import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "perawatan";
const modelPath = `/${modelName}/`;

class Perawatan extends ModelBase {
  constructor() {
    let model = {
      id: null,
      tanggal_masuk: "",
      pasien: "",
      komponen: "",
      komponen_list: []
    };
    super(modelName, model, [], [], modelPath);
  }
}

export default Perawatan;